<template>
  <van-popup
    v-model="showPicker"
    class="fill-in-wrap-pop"
    position="right"
    style="width: 100%; height: 100%"
  >
    <div class="fill-in-wrap">
      <div class="content fillin-upload-ctn">
        <van-cell title-class="fillin-upload-ctn-title">
          <template #title>
            <span v-if="itemdata.isRequired == 'Y'" class="color-r m_r_8"
              >*</span
            >
            <span class="custom-title">{{ itemdata.materialName }}</span>
          </template>
          <template #label>
            <div class="dis_flex ali_it_cen">
              <span
                v-if="itemdata.exampleUrl"
                class="custom-title color-blue m_r_16"
                @click="previewDemo(itemdata.exampleUrl)"
              >
                <van-icon name="eye-o" />
                示例
              </span>
              <span class="custom-title"
                >({{ acceptTipsFn(itemdata.imageType) }})</span
              >
            </div>
          </template>
        </van-cell>
        <div class="p_20_32">
          <Upload
            v-model="itemdata.lists"
            previewVali="mobile"
            :maxCount="9"
            :isDefaultAccept="false"
            :accept="acceptFn(itemdata.imageType)"
            @upload="saveMaterFn"
            @del="deleteMaterFn"
          />
        </div>
      </div>
      <div class="btns">
        <van-button size="small" @click="handleClose">返回</van-button>
        <van-button type="info" size="small" @click="saveFn">保存</van-button>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { ImagePreview } from "vant";
import { saveMaterialsImg, delMaterialsImg } from "@/api/apply";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // 需要填的表单内容
    itemdata: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Upload: () => import("@/components/Upload.vue")
  },
  watch: {
    showPicker(v) {
      if (v) {
        try {
          console.log("this.itemdata :>> ", this.itemdata);
          this.init();
        } catch (e) {
          console.log("查看报错", e);
        }
      } else {
        this.$emit("update");
      }
    }
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  data() {
    return {
      pageLoading: false
    };
  },
  created() {},
  methods: {
    init() {
      this.getProductConfigs();
    },
    // 产品配置
    async getProductConfigs() {
      try {
        this.pageLoading = true;
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    previewDemo(url) {
      ImagePreview({ images: [url], closeable: true });
    },
    async saveMaterFn(datum) {
      try {
        this.pageLoading = true;
        if (this.itemdata.isNeed == "1" && !this.itemdata.lists.length) {
          this.$toast("请上传" + this.itemdata.materialName);
          return;
        }
        const { bizNo, materialCode, materialName, capitalMaterialCode } =
          this.itemdata;
        const params = {
          bizNo,
          capitalMaterialCode,
          materialCode,
          materialName,
          imageName: datum.name,
          imageUrl: datum.url,
          imageType: this.itemdata.imageType
        };
        await saveMaterialsImg(params);
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    async deleteMaterFn(index, { id }) {
      try {
        await delMaterialsImg(id);
        this.itemdata.lists.splice(index, 1);
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async saveFn() {
      this.$emit("update");
      this.showPicker = false;
    },
    handleClose() {
      // 用户点击关闭图标时触发
      this.showPicker = false;
      this.$nextTick(() => {
        this.showPicker = false;
      });
    },
    acceptFn(fileType) {
      if (fileType == "PIC") {
        return ".jpg, .jpeg, .png";
      } else if (fileType == "VIDEO") {
        return "video/*";
      } else if (fileType == "DOC") {
        return ".pdf,.xlsx,.csv,.xls";
      } else {
        return "image/*,video/*";
      }
    },
    acceptTipsFn(fileType) {
      if (fileType == "PIC") {
        return "请上传.jpg, .jpeg, .png格式文件";
      } else if (fileType == "VIDEO") {
        return "请上传视频格式文件";
      } else if (fileType == "DOC") {
        return "请上传pdf格式文件";
      } else {
        return "image/*,video/*";
      }
    },
    clickPreviewFn(item) {
      this.itemInfo = item || {};
      this.showitemPicker = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.fill-in-wrap-pop {
  :deep(.fill-in-wrap) {
    height: 100%;
    display: flex;
    flex-direction: column;
    .head {
      height: 96px;
      text-align: center;
      line-height: 96px;
      font-size: 32px;
      font-weight: 600;
      color: #333;
      border-bottom: 1px solid #efefef;
    }
    .content {
      flex: 1;
      overflow-y: auto;

      .color-r {
        color: red;
      }
      .color-blue {
        color: rgb(24, 144, 255);
      }
    }
    .fillin-upload-ctn-title {
      min-width: 65vw;
    }
    .btns {
      border-top: 1px solid #efefef;
      height: 112px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-shadow: 0 -5px 10px #efefef;
      .van-button {
        min-width: 160px;
      }
    }
  }
}
</style>
